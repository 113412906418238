export const format = {
  methods: {
    toBRL(value) {
      const formattedValue = Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }).format(value)

      return formattedValue
    },
    dateFormatted(rawDate) {
      const months = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ];
      
      let [year, month, day] = rawDate.split('-');
      const monthIndex = month[0] == 0 ? Number(month.slice(-1) - 1) : Number(month - 1);
      const monthText = months[monthIndex];
      const dateToString = `${day} ${monthText}, ${year}`;
      return dateToString;
    },
  }
}