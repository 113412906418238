<template>
  <card type="chart">
    <LoadingSpinner :show="loading" />
    <template slot="header">
      <h5 class="card-category">
        <i class="tim-icons icon-basket-simple text-primary"></i> Gráfico
        Produtos
      </h5>
      <h2 class="card-title">{{ total }}</h2>
    </template>
    <div class="chart-area" style="overflow: scroll; height: 450px">
      <div class="box">
        <apexchart
          width="100%"
          height="400px"
          type="bar"
          :options="options"
          :series="series"
        ></apexchart>
      </div>
    </div>
  </card>
</template>

<script>
import LoadingSpinner from "@/components/Spinner.vue";

export default {
  name: "ProductChart",
  components: {
    LoadingSpinner,
  },
  props: {
    startDate: String,
    endDate: String,
    selectedMachine: String,
  },
  data() {
    return {
      total: 0,
      options: {
        chart: {
          foreColor: "#fff",
          id: "vuechart-products",
          stacked: true,
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: "center",
              hideOverflowingLabels: false,
            },
            horizontal: true,
          },
        },
        tooltip: {
          shared: false,
          y: {
            formatter: function (value) {
              if (value < 0) return Math.abs(value);
              return new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(Math.abs(value));
            },
          },
        },
        xaxis: {
          labels: {
            show: false,
            style: {
              colors: ["#fff"],
            },
          },
          categories: [],
        },
        yaxis: {
          labels: {
            style: {
              colors: ["#fff"],
            },
          },
        },
        dataLabels: {
          enabled: true,
          margin: 100,
          style: {
            colors: ["#fff"],
          },
          textAnchor: "middle",
          formatter: function (value) {
            if (value < 0) return Math.abs(value);
            return new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(Math.abs(value));
          },
        },
        stroke: {
          width: 2,
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "top",
          labels: {
            colors: "#fff",
          },
        },
      },
      series: [
        {
          name: "Total",
          data: [0],
        },
        {
          name: "Quantidade",
          data: [0],
        },
      ],
      loading: false,
    };
  },
  methods: {
    createChart({ datasets, labels }) {
      const options = { ...this.options };
      options.xaxis.categories = labels;
      this.options = options;
      const series = [];
      for (const index in datasets) {
        series.push({
          name: datasets[index].label,
          data: datasets[index].data,
        });
      }
      this.series = series;
    },
    createProductChart(productDatasetQuant, productDatasetTotal) {
      if (!productDatasetQuant && !productDatasetTotal) return;
      const dataQuant = productDatasetQuant;
      const dataTotal = productDatasetTotal;
      const datasets = [
        { data: dataQuant.data, label: "Quantidade" },
        { data: dataTotal.data, label: "Total" },
      ];
      this.createChart({
        datasets,
        labels: dataQuant.labels,
      });
    },
    validateStartAndFinalDate() {
      return this.endDate || this.startDate;
    },
    async getProducts() {
      this.loading = true;
      const response = await this.$http.get(
        `/graph/products?start_date=${this.startDate}&end_date=${this.endDate}&machine_id=${this.selectedMachine}`
      );
      const { products } = response.data;
      this.loading = false;
      return products;
    },
    async generateProductsDataset() {
      try {
        const products = await this.getProducts();
        products
          .sort(function (productA, productB) {
            return productA.total - productB.total;
          })
          .reverse();
        if (products) {
          const productsChartDataQuant = { labels: [], data: [] };
          const productsChartDataTotal = { labels: [], data: [] };
          let totalOfProducts = 0;
          for (let product of products) {
            totalOfProducts += product.total;
            const productName = product.title.split(" ");
            const name = `${productName[0]}`;
            if (productName[1]) {
              name += ` ${productName[1]}`;
            }
            productsChartDataQuant.labels.push(name);
            productsChartDataQuant.data.push(-Math.abs(product.amount));
            productsChartDataTotal.labels.push(name);
            productsChartDataTotal.data.push(product.total / 100);
          }
          this.total = new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(Math.abs(totalOfProducts / 100));
          localStorage.setItem(
            "@Snackly:productChartData",
            JSON.stringify([productsChartDataQuant, productsChartDataTotal])
          );
          return [productsChartDataQuant, productsChartDataTotal];
        }
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    async generateProudctChart() {
      if (!this.validateStartAndFinalDate()) return;
      const [productsDatasetQuant, productsDatasetTotal] =
        await this.generateProductsDataset();
      this.createProductChart(productsDatasetQuant, productsDatasetTotal);
    },
  },
  watch: {
    selectedMachine() {
      this.generateProudctChart();
    },
    startDate() {
      this.generateProudctChart();
    },
    endDate() {
      this.generateProudctChart();
    },
  },
};
</script>
<style></style>