<template>
  <loader
    v-show="show"
    object="#00d6b4"
    color1="#ffffff"
    color2="#1d8cf8"
    size="3"
    speed="3"
    bg="#343a40"
    objectbg="#999793"
    opacity="80"
    name="circular"
  ></loader>
</template>

<script>
export default {
  name: "LoadingSpinner",
  props: {
    show: Boolean,
    text: String,
  },
};
</script>

<style scoped>
h1 {
  z-index: 1080;
}
</style>